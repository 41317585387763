@use "@angular/material" as mat;
@use "@ciphr/material" as ciphr-mat;

.mat-calendar-body-in-range::before {
  background: mat.get-color-from-palette(ciphr-mat.$primary-palette, 100);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: mat.get-color-from-palette(ciphr-mat.$primary-palette, 100);
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: mat.get-color-from-palette(ciphr-mat.$primary-palette, 600);
}

.mat-calendar-period-button {
  font-size: 14px !important;
  padding: 0 0.5rem !important;
}
