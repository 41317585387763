@use "sass:map";
@use "../../../../../node_modules/@angular/material/index" as mat;

@mixin spinner-theme($theme, $neutral-palette) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");

  .btn-spinner::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: mat.get-color-from-palette($primary-palette, 500);
    animation: spinner 0.8s linear infinite;
    z-index: 20;
  }

  .btn-spinner {
    pointer-events: none;
    color: transparent !important;
    background: mat.get-color-from-palette($neutral-palette, 200) !important;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
