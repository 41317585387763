%button {
  letter-spacing: 0.1px;
  line-height: 20px;
  padding: 0.625rem 1.5rem;
  box-shadow: none !important;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
}

button.mdc-button,
button.mdc-flat-button,
button.mdc-raised-button {
  @extend %button;

  .mdc-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.icon {
    padding: 0.625rem 1.5rem 0.625rem 1.125rem;
    mat-icon {
      font-size: 1.125rem;
      width: 1.125rem;
      height: 1.125rem;

      margin-right: 0.5rem !important;
    }
  }
}

.rounded {
  &.mdc-button,
  &.mdc-flat-button,
  &.mdc-raised-button {
    border-radius: 100px;
  }
}

.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-flat-button > .mat-icon {
  margin-right: 0 !important;
}
