@mixin respond-to-media($media) {
  @if $media == handset {
    @media (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == handset-portrait {
    @media (max-width: 599.99px) and (orientation: portrait) {
      @content;
    }
  } @else if $media == handset-landscape {
    @media (max-width: 959.99px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == tablet {
    @media (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait),
      (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == tablet-portrait {
    @media (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait) {
      @content;
    }
  } @else if $media == tablet-landscape {
    @media (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == web {
    @media (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == web-landscape {
    @media (min-width: 1280px) and (orientation: landscape) {
      @content;
    }
  }
}
