@use "sass:map";
@use "../../../../../node_modules/@angular/material/index" as mat;

@mixin pagination-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");
  $dark-primary-text-opacity: rgba(black, 0.87);
  $dark-disabled-text: rgba(black, 0.38);

  .pagination {
    .page {
      color: $dark-primary-text-opacity;
      background-color: #e9ecef;

      &.current {
        color: #003d52;
        background-color: mat.get-color-from-palette($accent-palette, 500);
      }
    }

    .first,
    .last {
      color: $dark-disabled-text;
    }
  }
}
